<template>
  <div>
    <navbar :searchText="data.keyword" @cancel="cancel" @search="search"/>
    <div class="list-content">
      <div v-if="!searchList.length" class="empty">
        暂无相关厂商
      </div>
      <div v-for="(item,index) in searchList" :key="index" class="list" @click="toMap(item)">
        <img :src="item.orgLogo" alt=""/>
        <div class="company-content">
          <div class="text-title">
            <div class="title-top">
              <span class="title">{{ item.orgName }}</span>
              <div @click.stop="toDetail(item)"><span>进店</span><img src="../../assets/shape@2x.png" alt=""></div>
            </div>
            <div class="type-list">
              <div v-for="(item ,index) in item.orgTypeList" :key="index" class="title-bottom">
                <img :src="item === '1' ? machining : item === '2' ? intelligent : buisness" alt=""/>
                <span>{{ item === '1' ? '大板云工厂' : item === '2' ? '成品云工厂' : '材料商' }}</span>
              </div>
            </div>
            <span class="address">{{ item.orgAddr }}</span>
            <div v-if="item.labelList.length" class="tags-list">
              <div v-for="(value,key) in item.labelList" :key="key" class="tags">
                {{ value.labelName }}
              </div>
            </div>
          </div>
          <!--          <div class="btn" @click.stop="toDetail(item.orgId)">-->
          <!--            <span>进店</span>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from '../../components/navbar-search'
import Api from '../../api'

const api = new Api()
export default {
  components: {
    navbar
  },
  data() {
    return {
      // 大板云工厂
      intelligent: require('../../assets/icon_intelligent@2x.png'),
      // 成品云工厂
      machining: require('../../assets/icon_machining@2x.png'),
      // 材料商
      buisness: require('../../assets/icon_buisness@2x.png'),
      data: this.$route.query,
      searchList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      api.getMapSearch(this.data).then(res => {
        res.data.map(item => {
          const list = item.orgTypeList.join(' ')
          list.indexOf('1') !== -1 ? item.orgType = 1 : list.indexOf('2') !== -1 ? item.orgType = 2 : item.orgType = 3
        })
        this.searchList = res.data
      })
    },
    search(data) {
      this.data.keyword = data
      this.getList()
    },
    cancel() {
      this.$router.push({
        path: "/"
      });
    },
    // 查看地图详情
    toMap(item) {
      this.$router.push({
        path: "/map",
        query: {
          orgId: item.orgId,
          orgType: item.orgType,
          latitude: item.lat,
          longitude: item.lng
        }
      });
    },
    // 跳转详情
    toDetail(item) {
      if (item.orgTypes.indexOf("3") != -1) {
        // 跳转到大板市场店铺页
        window.location.href = process.env.VUE_APP_SHOP_SERVER + '/Content/Vue/new-shop/my-shop.html?officeCode=' + item.orgId + '&isShare=2'
      } else {
        // 跳转到产业地图店铺页
        this.$router.push({
          path: "/detail",
          query: {
            orgId: item.orgId
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  font-size: 14px;
}

.list-content {
  padding: 4px 0 4px 15px;

  .list {
    // width: 100%;
    padding: 15px 15px 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #e8e8e8;

    img {
      width: 75px;
      height: 75px;
      border-radius: 4px;
      border: 1px solid #EBEDF0;
      margin-right: 10px;
    }

    .company-content {
      //display: flex;
      //justify-content: space-between;
      //align-items: flex-start;
      width: calc(100% - 87px);

      .text-title {
        //min-width: calc(100% - 52px);
        width: 100%;
        display: flex;
        flex-direction: column;

        .title-top {
          font-size: 12px;
          color: #989DA6;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          div {
            display: flex;
            align-items: center;
          }

          img {
            width: 10px;
            height: 10px;
            border: none;
            margin-left: 1px;
            margin-right: 0;
          }
        }

        .title {
          font-size: 16px;
          color: #333;
          line-height: 16px;
          font-weight: normal;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }

        .type-list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: nowrap;
          margin-bottom: 8px;
          flex-direction: row;

          .title-bottom {
            margin-right: 5px;
            max-width: 82px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            border-radius: 2px;
            background: #F7F8FA;
            padding: 2px 4px;
            align-items: center;
            font-size: 12px;
            color: #ccc;
            font-weight: 400;

            &:last-child {
              margin-right: 0;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }
          }
        }

        .address {
          max-width: 208px;
          width: 100%;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          font-size: 13px;
          font-weight: 400;
          color: #ccc;
          margin-bottom: 7px;
        }

        .tags-list {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: wrap;

          .tags {
            margin-right: 5px;
            margin-bottom: 7px;
            border-radius: 2px;
            border: 1px solid #DCDEE0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 400;
            padding: 3px 5px;
          }
        }
      }

      .btn {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DCDEE0;
        width: 50px;
        height: 28px;
        border-radius: 4px;
      }
    }
  }

}
</style>
